<template>
    <div>
        <div class="voiceLogo"></div>
        <!-- 头部菜单 -->
        <div class="mainPageHead">
            <ul class="headNav" @mouseleave="handleMouseleave">
                <label class="redBlock"
                    :style="{ left: (1200 / 7 * (hoverCurrent - 1) >= 0 ? 1200 / 7 * (hoverCurrent - 1) : 0) + 'px', width: hoverCurrent === 0 ? 0 : '' }"></label>
                <li class="navUnit" v-for="(item, index) in navMenu" :key="index"
                    :class="{ 'activeRed': routerName === item.name, 'cardCon': item.submenu && item.submenu.length > 0 }"
                    @mouseenter="handleMouseenter(index + 1)">
                    <router-link v-if="!item.submenu" :to="item.path">{{ item.title }}</router-link>
                    <span v-else>
                        {{ item.title }}
                    </span>
                    <div class="hiddenCard" v-if="item.submenu && item.submenu.length > 0">
                        <div v-for="(citem, cindex) in item.submenu" :key="cindex"><router-link class="cardLine"
                                :class="{ 'active': typeCurrent == cindex + 1 }"
                                :to="citem.path">{{ citem.title }}</router-link></div>
                    </div>
                </li>

                <li class="navUnit cardCon" @mouseenter="handleMouseenter(navMenu.length + 1)">在线收听<label
                        class="navLogo"></label>
                    <div class="hiddenCard">
                        <div>
                            <a href="javascript:;" class="cardLine"
                                onclick="window.open(('http://www.cztv.com/radio/fm88/?cid=1601'), '新蓝听听', 'height=76, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no');">浙江之声</a>
                        </div>
                        <div>
                            <a href="javascript:;" class="cardLine"
                                onclick="window.open(('http://www.cztv.com/radio/fm988/?cid=1608'), '新蓝听听', 'height=76, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no');">FM988</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hoverCurrent: 0,
            routerName: '',
            typeCurrent: undefined,
            navMenu: [
                {
                    title: '首页',
                    name: 'Home',
                    path: '/',
                },
                {
                    title: '频道介绍',
                    name: 'Channel',
                    path: '/channel?reload=1',
                },
                {
                    title: '主持人',
                    name: 'Hoster',
                    path: '/hoster',
                },
                {
                    title: '行业动态',
                    name: 'Active',
                    path: '/active',
                    submenu: [
                        {
                            title: '旅游',
                            path: '/active?type=1'
                        },
                        {
                            title: '房产',
                            path: '/active?type=2'
                        },
                        {
                            title: '家装',
                            path: '/active?type=3'
                        },
                        {
                            title: '财经',
                            path: '/active?type=4'
                        },
                        {
                            title: '汽车',
                            path: '/active?type=5'
                        },
                        {
                            title: '健康',
                            path: '/active?type=6'
                        },
                        {
                            title: '时尚',
                            path: '/active?type=7'
                        },
                    ]
                },
                {
                    title: '各地收听频率',
                    name: 'Frequency',
                    path: '/frequency?reload=1',
                },
                {
                    title: '广告价目表',
                    name: 'Advert',
                    path: '/advert?reload=1',
                },
            ]
        }
    },
    methods: {
        handleRouter(route) {
            if (route.name === 'Active') {
                this.typeCurrent = route.query.type
            }else{
                this.typeCurrent = undefined
            }
            if (route.name === 'HosterDetail') {
                this.routerName = 'Hoster'
            }
            if (route.name === 'HomeDetail') {
                this.routerName = 'Home'
            }
            if (route.name === 'NewsList') {
                this.routerName = 'Home'
            }
            if (route.name === 'ActiveList') {
                this.routerName = 'Active'
            }
            if (route.name === 'ActiveDetail') {
                this.routerName = 'Active'
            }
            
        },
        handleMouseleave() {
            this.hoverCurrent = 0
        },
        handleMouseenter(current) {
            this.hoverCurrent = current
        }
    },
    watch: {
        '$route': {
            handler(val) {
                this.routerName = val.name
                this.handleRouter(val)
            },
            deep: true
        }
    },
    created() {
        this.routerName = this.$route.name
        this.handleRouter(this.$route)
    }
}
</script>

<style lang="scss" scoped>
.voiceLogo {
    width: 340px;
    height: 72px;
    margin-bottom: 30px;
    background-image: url(../../assets/img/logo.png);
    background-size: 100% 100%;
}

.mainPageHead {
    width: 100%;
    height: 60px;
}

.replaceCon {
    width: 100%;
}

.headNav {
    width: 100%;
    height: 60px;
}

.headNav {
    display: flex;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: '微软雅黑';
    font-size: 20px;
    color: white;
    background: black;
    position: relative;
}

.navUnit {
    width: -webkit-calc(100% / 7);
    width: -moz-calc(100% / 7);
    width: -ms-calc(100% / 7);
    width: -o-calc(100% / 7);
    width: calc(100% / 7);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    cursor: pointer;
    position: relative;
    z-index: 10;
}

.navUnit>a {
    width: 100%;
    height: 100%;
    display: inline-block;
    font-family: '微软雅黑';
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navLogo {
    display: inline-block;
    width: 42px;
    height: 42px;
    background-image: url("../../assets/img/nav-logo.png");
    background-size: 100% 100%;
    margin-left: 5px;

}

.redBlock {
    width: -webkit-calc(100% / 7);
    width: -moz-calc(100% / 7);
    width: -ms-calc(100% / 7);
    width: -o-calc(100% / 7);
    width: calc(100% / 7);
    display: block;
    height: 100%;
    position: absolute;
    z-index: 5;
    background: red;
    transition: 0.3s
}

.activeRed {
    background: red;
}

.hiddenCard {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 60px;
    left: 0;
    display: none;
}

.cardCon:hover {
    .hiddenCard {
        display: block;
    }
}



.cardLine {
    width: 100%;
    height: 45px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.cardLine.active {
    background: rgba(0, 0, 0, 0.3);
    color: red;
}

.cardLine:hover {
    background: rgba(0, 0, 0, 0.3);
    color: red;
}</style>