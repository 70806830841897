
import { SUCC_CODE, TIMEOUT, BASE_URL } from './config.js';


const interceptors = (axios) => {
  const service = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
  })

  service.interceptors.request.use(
    config => {
      //  请求/通信 加密
      // const  headers={
      //   // 'Content-Type': 'application/json;charset=utf-8',
      //  ' Accept': 'application/json'
      // }

      // config.headers = Object.assign(headers, config.headers);

      return config;
    },
    error => {
      Promise.error(error)
    }
  );

  service.interceptors.response.use(
    res => {
      // console.log('service res = ', res);
      if (res.status == SUCC_CODE || res.status == '201') {
        return Promise.resolve(res.data);
      } else {
        const err = new Error(res.statusText);
        err.name = res.status;
        return Promise.reject(err);
      }

    }, error => {
      console.log('error', res);
      
      let errorMsg = "抱歉，请求数据失败~";

      if (error.response) {
        if (typeof error.response.data.message === "string") {
          errorMsg = error.response.data.message || "抱歉，请求数据失败~"
        }

      } else if (error.request) {
        if (typeof error.request === "string") {
          errorMsg = error.request || "抱歉，请求数据失败~"
        }

      } else if (error.message) {
        if (typeof error.message === "string") {
          errorMsg = error.message || "抱歉，请求数据失败~"
        }

      } else {
        // console.log('response Error', error);
        if (typeof error === "string") {
          errorMsg = error || "抱歉，请求数据失败~"
        }
      }

      const errItem = {
        message: errorMsg,
        // code:
      };

      return Promise.reject(errItem);
    }
  )

  return service
}
export default interceptors;



