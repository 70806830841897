<template>
  <div id="app">
    <div class="detailsBox">
      <zmg-head></zmg-head>
      <div class="main">
        <router-view />
      </div>
    </div>
    <zmg-foot></zmg-foot>
  </div>
</template>

<script>
import ZmgHead from "./components/head";
import ZmgFoot from "./components/foot";
export default {
  name: "App",
  components: {
    ZmgHead,
    ZmgFoot,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {},
  // mounted () {
  //   window.addEventListener('scroll', this.scrollToTop)
  // },
  // methods: {
  //   scrollToTop() {
  //     this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  //     console.log('scrollTop',this.scrollTop);
  //   },
  // }
};
</script>

<style lang="scss" scoped>
#app {
  // background: #ffffff;
  box-sizing: border-box;
  width: 100vw;
  min-width: 1200px;
  background-image: url("./assets/img/bg.png");
  background-size: 100% auto;
}

.detailsBox {
  width: 1200px;
  margin: 0 auto;
}

.main {
  // height: 300px;
  width: 100%;
  min-height: calc(100vh - 332px);
}
</style>
