<template>
    <div class="pageBoot">
        <div class="bootInside">
            <div class="linkLine">
                <span>友情链接：</span>
                <span><a href="http://www.zjstv.com/" target="_blank">浙江卫视</a></span> |
                <span><a href="http://www.cztv.com/" target="_blank">新蓝网</a></span> |
                <span><a href="http://tv.cztv.com/" target="_blank">中国蓝TV</a></span> |
                <span><a href="http://tv.cztv.com/voice/" target="_blank">中国新歌声在线观看</a></span>
            </div>
            <div class="botCons"><a href="https://beian.miit.gov.cn">浙ICP备05052141号-13</a>　浙江之声频道版权所有All Rights Reserved
                Copyright 2011</div>
            <div class="botCons">浙江新蓝网络传媒有限公司 提供技术支持</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.pageBoot{
    width:100%;
    height:170px;
    background:#464646;
}
.bootInside{
    width:1000px;
    height:100%;
    margin:0 auto;
    padding-top: 40px;
    box-sizing: border-box;
}
.linkLine{
    width:100%;
    text-align: center;
    color:#eaeaea;
    margin-bottom: 30px;
}
.linkLine span{
    color:#eaeaea;
    font-size:14px;
    margin:0 5px;
}
.linkLine span a{
    color:inherit;
}
.botCons{
    width:100%;
    text-align: center;
    font-size:14px;
    color:#999;
    a{
        color:#999;
    }
}
</style>