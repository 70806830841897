import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: "Home",
    meta:{
      title:'浙江之声首页'
    },
    component: () => import('@/views/home'),
  },
  {
    path: '/homeDetail/:id',
    name: "HomeDetail",
    meta:{
      title:'浙江之声'
    },
    component: () => import('@/views/activeDetail'),
  },
  {
    path: '/newsList',
    name: "NewsList",
    meta:{
      title:'新闻资讯'
    },
    component: () => import('@/views/active/list'),
  },
  {
    path: '/activeList',
    name: "ActiveList",
    meta:{
      title:'活动信息'
    },
    component: () => import('@/views/active/list'),
  },
  {
    path: '/channel',
    name: "Channel",
    meta:{
      title:'浙江之声 浙江新闻广播'
    },
    component: () => import('@/views/channel'),
  },
  {
    path: '/hoster',
    name: "Hoster",
    meta:{
      title:'浙江之声主持人'
    },
    component: () => import('@/views/hoster'),
  },
  {
    path: '/hosterDetail/:id',
    name: "HosterDetail",
    meta:{
      title:'浙江之声主持人介绍'
    },
    component: () => import('@/views/hoster/detail'),
  },
  {
    path: '/active',
    name: "Active",
    meta:{
      title:'行业动态'
    },
    component: () => import('@/views/active'),
  },
  {
    path: '/activeDetail/:id',
    name: "ActiveDetail",
    meta:{
      title:'行业动态'
    },
    component: () => import('@/views/activeDetail'),
  },
  {
    path: '/activeDetail/:id',
    name: "ActiveDetail",
    meta:{
      title:'行业动态'
    },
    component: () => import( /* webpackChunkName: "dynamic" */ '@/views/activeDetail'),
  },
  {
    path: '/frequency',
    name: "Frequency",
    meta:{
      title:'各地收听频率'
    },
    component: () => import('@/views/frequency'),
  },
  {
    path: '/advert',
    name: "Advert",
    meta:{
      title:'广告价目表'
    },
    component: () => import('@/views/advert'),
  },
  {
    path: '/error',
    name: "Error",
    meta:{
      title:'404'
    },
    component: () => import('@/views/error'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  // base: '/',//history模式
  scrollBehavior: () => ({y: 0}),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next("/error");
  } else {
    if(to.meta.title) document.title = to.meta.title
    next();
  }
})
export default router
