

//获取地址栏的信息
const getUrlParameters = function () {

    const href = location.href || "";
    // const href = "https://h5.cztv.com/batrix-h5/h5/quiz/?id=3&stemFrom=ios&token=S1021df066b3013665f44a5e4e299b930a8c9&sessionId=S1021df066b3013665f44a5e4e299b930a8c9&sourceid=21";

    // console.log('href = ',href);

    if (typeof href != "string" || href === "" || href.length === 0) {
        return {}
    }

    const hrefArray = href.split('?') || [];

    if (hrefArray.length < 2) {
        return {}
    }

    const subHref = hrefArray[1] || "";

    if (typeof subHref != "string" || subHref === "" || subHref.length === 0) {
        return {}
    }

    const subArray = subHref.split('&');
    const params = {}
    for (let i = 0; i < subArray.length; i++) {
        const data = subArray[i].split('=')
        if (data.length === 2) {
            params[data[0]] = data[1]
        }
    }
    return params
}

// 获取url对应key值
const getUrlParameterWithKey = function (key) {
    const params = getUrlParameters()
    return params[key]
}

// 获取 SessionStorage 存储的 数据
const getSessionStorageItem = function (key) {
    const itemStr = window.sessionStorage.getItem(key) || "";
    // console.log('itemStr = ', itemStr);
    // console.log(' JSON.parse(itemStr) = ', JSON.parse(itemStr),typeof JSON.parse(itemStr));
    if (itemStr == "") {
        return "";
    }
    else {
        return JSON.parse(itemStr);
    }

}

// 把 数据 存储在 SessionStorage
const setSessionStorageItem = function (key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
}


export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

//导出
export {
    getUrlParameters,
    getUrlParameterWithKey,
    getSessionStorageItem,
    setSessionStorageItem,
}