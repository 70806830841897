import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './style/reset.css'
import './style/base.css'
import './assets/font/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
// element ui 主题
import "./style/element-variables.scss";
import {parseTime} from './utils/tools'
import axios from 'axios';
// import ElementUI from 'element-ui'; //完整引入
import { Message,Loading,Upload,Icon,Input, Carousel,
  CarouselItem, Pagination} from 'element-ui'; //单独引入
// 数据拦截器
import interceptors from "./utils/api/request.js";
const dayjs = require('dayjs')
Vue.config.productionTip = process.env.NODE_ENV === 'development';
Vue.component(CollapseTransition.name, CollapseTransition)

Vue.use(Loading);
Vue.use(Upload);
Vue.use(Icon)
Vue.use(Input)
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);

Vue.prototype.axios = interceptors(axios);
Vue.prototype.$message = Message;
Vue.prototype.dayjs = dayjs;
Vue.prototype.parseTime = parseTime

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
