export const SUCC_CODE = '200'; //接口请求成功 正确码
export const TIMEOUT = 2000; //接口请求超时时间
// export const BASE_URL = window.location.host=='zmg-local.com.cn'?'http://p.cztv.com.cn/':'https://proxy.cztvcloud.com/'
// export const BASE_URL = process.env.NODE_ENV === 'production' ? 'http://interact-local.cztv.com/' : 'http://interact-local.cztv.com/'
export const BASE_URL = process.env.VUE_APP_BASE_API
// const isProduction = window.location.host !== 'zmg-local.com.cn'
const isProduction = process.env.NODE_ENV === 'production'
// const isProduction = true

export const APPID = !isProduction ? 1056 :1056  //APPID 
export const HOMELB = !isProduction ? 1613 :2834; //首页轮播
export const NEWS = !isProduction ? 1614 :2835; //新闻资讯
export const ACTIVE = !isProduction ? 1615 :2836;//活动信息
export const HOST = !isProduction ? 1616 :2837; //主持人轮播
export const CHANNEL = !isProduction ? 1617 :2838;//频道介绍
export const FREQUENCY = !isProduction ? 1618 :2839;//各地收听频率
export const ADVERT = !isProduction ? 1619 :2840;//广告价目表
export const INDUSTRY = !isProduction ? 1620 :2841;//行业动态
export const TRAVEL = !isProduction ? 1621 :2842;//旅游
export const HOUSE = !isProduction ? 1622 :2843;//房产
export const DESIGN = !isProduction ? 1623 :2844;//家装
export const FINANCE = !isProduction ? 1624 :2845;//财经
export const CARS = !isProduction ? 1625 :2846;//汽车
export const HEALTH = !isProduction ? 1626 :2847;//健康
export const FASHION = !isProduction ? 1627 :2848;//时尚








